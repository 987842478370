<template>
    <div :class="position">
        <div class="zuo">
            <!-- <img src="../assets/img/zuo.png" alt=""> -->
            <img v-lazy="imgObj" alt="">
        </div>
        <div class="text" v-html="props.text"></div>
        <div :class="nodeclass">
            <!-- <img src="../assets/img/you.png" alt=""> -->
            <img v-lazy="imgObj2" alt="">
        </div>
    </div>
</template>

<script setup>
const imgObj = require('../assets/img/zuo@2x.png')
const imgObj2 = require('../assets/img/you@2x.png')
const props = defineProps(['text','Id','position'])
const nodeclass = props.Id?'you2':'you'
const position = props.position?'title1':'title'
</script>

<style scoped>
.title{
    display: flex;
    height: 59px;
    justify-content: center;
}
.title1{
    display: flex;
    height: 59px;
}
.text{
    font-size: 42px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2A2A2A;
    line-height: 59px;
    z-index: 9;
}
.zuo img{
    width: 40px;
    height: 60px;
    margin-right: -20px;
}
.you img{
    width: 29px;
    height: 42px;
    margin-top: 17px;
    margin-left: -18px;
}
.you2 img{
    width: 29px;
    height: 42px;
    margin-top: 70px;
    margin-left: -18px;
}
</style>