<template>
    <div class="nav">
        <div class="conxt">
            <TitleComponent :text='text'></TitleComponent>
            <div class="card">
                <div v-for="(item,index) in data" :key="index" class="dev">
                    <div>
                        <!-- <img :src='item.icon' alt=""> -->
                        <img v-lazy='item.icon' alt="" :key="item.icon">
                        <p class="p1">{{item.title}}</p>
                        <p class="p2">{{item.text}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import TitleComponent from '../../../../components/titleComponent.vue'
import xitong from '../../../../assets/img/service/xitongfuwu@2x.png'
import jishu from '../../../../assets/img/service/jishufuwu@2x.png'
import anquan from '../../../../assets/img/service/anquanfuwu@2x.png'
const text = '我们为您提供全面的解决方案'
const data = [
    {
        icon:xitong,
        title:'系统服务',
        text:'通过我们的系统与技术摆脱旧式的业务模式采用全新的互联网模式去展业、推广、管理，实现保险销售服务闭环'
    },
    {
        icon:jishu,
        title:'技术服务',
        text:'IT专业技术人员根据不同的需求、场景，给您提供多样化解决方案，实现保险行业信息的数字化'
    },
    {
        icon:anquan,
        title:'安全服务',
        text:'采用全球领先技术，数据层层加密，有效保护客户隐私，保障系统安全稳定运行'
    },
]
</script>

<style scoped>
.nav{
    position: relative;
    height: 616px;
    background-color: #F7F7F9;
}
.conxt{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1200px;
    transform:translate(-50%,-50%);
}
.card .dev{
    width: 380px;
    height: 267px;
    background-color: #fff;
    border-radius: 10px;
}
.dev div{
    margin: 31px 0 0 30px;
}
.card{
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
}
.card .p1{
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2A2A2A;
    margin: 23px 0 15px 0;
}
.card img{
    width: 56px;
    height: 56px;
}
.card .p2{
    width: 320px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #848484;
    line-height: 23px;
}
</style>