<template>
    <div class="nav">
        <img v-lazy="imgObj" alt="" class="lazy">
        <div class="conxt">
            <div class="left">
                <p class="p1">超能保公众号，即刻开始展业</p>
                <p class="p2">超能保是秦保科技自主研发的保险业务销售、推广一体化的智能展业平台，使用他，您可以随时随地线上出单、营销、管理等业务需求</p>
                <ul>
                    <li v-for="item,index in data" :key="index">
                        <!-- <img :src="item.icon" alt=""> -->
                        <img v-lazy="item.icon" alt="" :key="item.icon">
                        <div>{{item.text}}</div>
                    </li>
                </ul>
            </div>
            <div class="right">
                <!-- <img src="../../../../assets/img/service/erweima.png" alt=""> -->
                <img v-lazy="imgObj2" alt="">
                <div>关注超能保，出单展业不耽误</div>
            </div>
        </div>
    </div>
</template>

<script setup>
import xinxi from '../../../../assets/img/service/xinxi@2x.png'
import fenxiao from '../../../../assets/img/service/fenxiao@2x.png'
import chanpku from '../../../../assets/img/service/chanpku@2x.png'
const imgObj = require('../../../../assets/img/service/gongzhonghao_bg.png')
const imgObj2 = require('../../../../assets/img/service/erweima.png')
const data = [
    {
        icon:xinxi,
        text:'模块信息化'
    },
    {
        icon:fenxiao,
        text:'优质渠道分销'
    },
    {
        icon:chanpku,
        text:'丰富产品库'
    }
]
</script>

<style scoped>
.nav{
    position: relative;
    /* height: 683px;
    background: url('../../../../assets/img/service/gongzhonghao_bg.png') no-repeat;
    background-size: 100% 100%; */
}
.lazy{
    /* position: relative; */
    height: 683px;
    width: 100%;
    vertical-align: bottom;
}
.conxt{
    position: absolute;
    width: 1200px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    /* margin-top: 158px; */
    display: flex;
    justify-content: space-between;
}
.left{
    margin-top: 27px;
}
.p1{
    font-size: 42px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
}
.p2{
    width: 629px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 29px;
    margin: 20px 0 86px 0;
}
.left ul{
    width: 410px;
    display: flex;
    justify-content: space-between;
}
.left ul li{
    list-style: none;
    text-align: center;
}
.left ul li img{
    width: 54px;
    height: 54px;
}
.left ul li div{
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    margin-top: 10px;
}
.right div{
    width: 260px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 23px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #848484;
    line-height: 40px;
    margin: 20px 0 0 20px;
    text-align: center;
}
</style>