<template>
    <div class="nav">
        <div class="conxt">
            <TitleComponent :text='text'></TitleComponent>
            <div class="card">
                <div v-for="(item,index) in data" :key="index" class="dev">
                    <div>
                        <!-- <img :src="item.icon" alt=""> -->
                        <img v-lazy="item.icon" alt="" :key="item.icon">
                        <p class="p1">{{item.title}}</p>
                        <p class="p2">{{item.text}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import TitleComponent from '../../../../components/titleComponent.vue'
import team from '../../../../assets/img/service/team_img.png'
import jishu_img from '../../../../assets/img/service/jishu_img.png'
import yunying_img from '../../../../assets/img/service/yunying_img.png'
const text = '强大的创作团队带领您达到一个新的高度'
const data = [
    {
        icon:team,
        title:'保险业务团队',
        text:'创始人是拥有16年保险行业经验的精英，对保险行业需求有深入研究'
    },
    {
        icon:jishu_img,
        title:'技术团队',
        text:'超过20年前腾讯互联网大牛带领的技术团队，专注于技术开发'
    },
    {
        icon:yunying_img,
        title:'产品运营团队',
        text:'负责人拥有8年资深保险产品经验，打造过知名互联网产品平台'
    },
]
</script>

<style scoped>
.nav{
    position: relative;
    height: 757px;
}
.conxt{
    position: absolute;
    width: 1200px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.conxt .dev{
    /* width: 380px; */
    height: 410px;
    background: #F7F7F9;
    border-radius: 10px;
}
.card{
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
}
.card .p1{
    font-size: 28px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2A2A2A;
    margin: 40px 0 10px 30px;
}
.card .p2{
    width: 320px;
    font-size: 14px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #848484;
    margin-left: 30px;
}
.dev img{
    width: 380px;
    height: 225px;
    transition: all 1s;
}
.dev img:hover{
    transform: scale(1.1);
}
</style>