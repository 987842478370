<template>
    <div class="nav">
        <img v-lazy='imgObj' alt="">
        <div class="conxt">
            <p class="p1">优质的服务支持</p>
            <p class="p2">我们尽最大的努力去为您构建利益最大化的保险合作模式</p>
        </div>
    </div>
</template>

<script setup>
const imgObj = require('../../../../assets/img/service/banner.png')
</script>

<style scoped>
.nav{
    position: relative;
    /* height: 500px;
    background: url('../../../../assets/img/service/banner.png') no-repeat;
    background-size: 100% 100%; */
}
.nav img{
    height: 500px;
    width: 100%;
    vertical-align: bottom;
}
.conxt{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1200px;
    transform:translate(-50%,-50%);
}
.conxt .p1{
    font-size: 68px;
    font-family: Alibaba-PuHuiTi-B, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #FFFFFF;
    margin-bottom: 16px;
}
.conxt .p2{
    font-size: 18px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: rgb(161, 161, 177);
}
</style>