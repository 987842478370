<template>
    <div class="nav">
        <div class="conxt">
            <TitleComponent :text='text'></TitleComponent>
            <div class="card">
                <div class="left">
                    <div v-for="(item,index) in left" :key="index">
                        <!-- <img :src="item.icon" alt=""> -->
                        <img v-lazy="item.icon" alt="" :key="item.icon">
                        <span>{{item.title}}</span>
                        <p>{{item.text}}</p>
                    </div>
                </div>
                <div class="center">
                    <img v-lazy="imgObj" alt="">
                </div>
                <div class="right">
                    <div v-for="(item,index) in right" :key="index">
                        <!-- <img :src="item.icon" alt=""> -->
                        <img v-lazy="item.icon" alt="" :key="item.icon">
                        <span>{{item.title}}</span>
                        <p>{{item.text}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import TitleComponent from '../../../../components/titleComponent.vue'
import qingliang from '../../../../assets/img/service/qingliang@2x.png'
import peixun from '../../../../assets/img/service/peixun@2x.png'
import zhuanyejishu from '../../../../assets/img/service/zhuanyejishu@2x.png'
import gaoxiao from '../../../../assets/img/service/gaoxiao@2x.png'
import kuaisu from '../../../../assets/img/service/kuaisu@2x.png'
import shouhou from '../../../../assets/img/service/shouhou@2x.png'
const text = '代理人/保险公司在秦保您可以获得的支持'
const imgObj = require('../../../../assets/img/service/zhichi_img@2x.png')
const left = [
    {
        icon:qingliang,
        title:'更轻量化',
        text:'AI大数据下的综合新兴平台，助您轻松展业'
    },
    {
        icon:peixun,
        title:'培训体系',
        text:'从基础课程到大咖经验分享等培训充分赋能'
    },
    {
        icon:zhuanyejishu,
        title:'专业技术支持',
        text:'专业互联网技术大牛在线支持，给您极致体验'
    }
]
const right = [
    {
        icon:gaoxiao,
        title:'高效处理',
        text:'协助客户高效工作，节省时间、降低运营成本'
    },
    {
        icon:kuaisu,
        title:'快速制定',
        text:'2小时打造专属您的智能云平台'
    },
    {
        icon:shouhou,
        title:'售后支持',
        text:'围绕用户保后服务，提供售后整合解决方案'
    }
]
</script>

<style scoped>
.nav{
    position: relative;
    height: 862px;
    background-color: #F7F7F9;
}
.conxt{
    position: absolute;
    width: 1200px;
    top: 50%;
    left: 50%;
    transform:translate(-50%,-50%);
}
.card{
    display: flex;
    justify-content: space-between;
    margin-top: 110px;
}
.left div{
    width: 325px;
    height: 98px;
    padding: 30px 0 0 18px;
    background: url('../../../../assets/img/service/zuo_bg.png') no-repeat;
    background-size: 100% 100%;
}
.left div:nth-child(1){
    margin-left: 28px;
}
.left div:nth-child(2){
    margin: 30px 0 30px 0;
}
.left div:nth-child(3){
    margin-left: 28px;
}
.left div img{
    width: 39px;
    height: 39px;
    vertical-align: middle;
    margin-right: 13px;
}
.left div span{
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2A2A2A;
}
.left div p{
    margin-top: 15px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #848484;
}
.center img{
    width: 437px;
    height: 458px;
    vertical-align: bottom;
    /* background: url('../../../../assets/img/service/zhichi_img.png') no-repeat;
    background-size: 100% 100%; */
}
.right div{
    width: 325px;
    height: 98px;
    padding: 30px 0 0 40px;
    background: url('../../../../assets/img/service/you_bg.png') no-repeat;
    background-size: 100% 100%;
}
.right div:nth-child(1){
    margin-left: 28px;
}
.right div:nth-child(2){
    margin: 30px 0 30px 0;
}
.right div:nth-child(3){
    margin-left: 28px;
}
.right div img{
    width: 39px;
    height: 39px;
    vertical-align: middle;
    margin-right: 13px;
}
.right div span{
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2A2A2A;
}
.right div p{
    margin-top: 15px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #848484;
}
</style>