<template>
    <div class="top">
        <FirstCard></FirstCard>
        <SecondCard></SecondCard>
        <ThreeCard></ThreeCard>
        <FourCard></FourCard>
        <FiveCard></FiveCard>
    </div>
</template>

<script setup>
import bus from '../../../utils/index'
import FirstCard from './components/first-card/index.vue'
import SecondCard from './components/second-card/index.vue'
import ThreeCard from './components/three-card/index.vue'
import FourCard from './components/four-card/index.vue'
import FiveCard from './components/five-card/index.vue'
bus.off('service')
bus.on('service',()=>{
    window,scrollTo(0,0);
})
</script>

<style scoped>
.top{
    margin-top: 80px;
}
</style>